import Api from "./baseApi";

const controllerPath = "/default";

const DefaultApi = {
  post(entity, data) {
    return Api.post(`${controllerPath}/${entity}`, data);
  },
  patch(entity, data) {
    return Api.patch(`${controllerPath}/${entity}`, data);
  },
  getAll(options) {
    return Api.getList(`${controllerPath}`, options);
  },
  get(entity, options) {
    return Api.get(`${controllerPath}/${entity}`, options);
  },
  delete(entity, keyValue) {
    return Api.delete(`${controllerPath}/${entity}/${keyValue}`, null);
  },
  export(entity, type, params) {
    return Api.request(`${controllerPath}/${entity}/download/${type}`, {...params, responseType: 'blob' });
  }
};

export default DefaultApi;
