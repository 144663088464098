// store.js
import { createStore, action, persist } from 'easy-peasy';

const authModel = {
  token: null, // Inicialmente o token é nulo
  isAuthenticated: false, // Estado de autenticação
  login: action((state, userData) => {
    state.token = userData.token;
    state.isAuthenticated = true;
    localStorage.setItem('authToken', userData.token); // Salva o token no localStorage
  }),
  logout: action((state) => {
    state.token = null;
    state.isAuthenticated = false;
    localStorage.removeItem('authToken'); // Remove o token do localStorage
  }),
  initializeAuth: action((state) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      state.token = token;
      state.isAuthenticated = true;
    }
  }),
};

const store = createStore(
  persist(
    {
      auth: authModel,
    },
    {
      storage: 'localStorage', // Usa o localStorage para persistir o estado
    }
  )
);

export default store;
