import { createBrowserHistory } from "history";

export const history = createBrowserHistory({
  basename: "/sso-slt",
});

export const push = (path, state) => history.push(path, state);

export const replace = (path, state) => history.replace(path, state);

export const goBack = () => history.goBack();

export default history;
