import React, { useState, useEffect } from 'react'
import { Nav, Navbar, NavDropdown, Container, Dropdown } from 'react-bootstrap'
import { useStoreActions } from "easy-peasy"
import { useNavigate, Link } from "react-router-dom"
import DefaultApi from "../../api/default"
import './style.css'
import logoInicial from '../../img/logo.png'

const Menu = () => {
	const logout = useStoreActions((action) => action.auth.logout);
	const navigate = useNavigate();

	const [menuJson, setMenuJson] = useState();
	const [parentMenus, setParentMenus] = useState();
	const [childMenus, setChildMenus] = useState();

	const handleLogout = async () => {
    logout()
    navigate("/login")
  };

  useEffect(() => {
		const getMenu = async () => {
			try{
				const filteredData = null;
				
				const json = await DefaultApi.get("menu", {
					params: { ...filteredData, pageNumber: 1, pageSize: 9999}
				})

				setMenuJson(json.data.data)

				setParentMenus(json.data.data.filter(e => e.type === "father"))
				setChildMenus(json.data.data.filter(e => e.type === "son"))

			} catch (error) {
				console.error('Erro ao buscar os dados:', error)
			}
		}

		getMenu()
  }, []);

	return (
		<Navbar data-bs-theme="dark" bg="dark" expand="md">
			<Container fluid>
				<Navbar.Brand as={Link} to="/home"><img src={logoInicial} width="80px" alt="logo"/></Navbar.Brand>
				<Navbar.Toggle aria-controls="navbar-dark-example" />
				<Navbar.Collapse id="navbar-dark-example">
					<Nav>
						{menuJson ? (
							<>
									{parentMenus?.map((parent, pIndex) => (
										<NavDropdown
											key={pIndex}
											data-bs-theme="dark"
											title={<>
												<i className={parent.icon}> </i>
												{parent.menu}
											</>}
										>
											{childMenus?.filter(child => child.parentmenu === parent.menu).map((child, cIndex) => (
												<NavDropdown.Item
													key={cIndex}
													onClick={() => navigate(`/home/${child.url}`)}
												>
													<i className={child.icon + " menuIcon"}></i>
													{child.menu}
												</NavDropdown.Item>
											
											))}
										</NavDropdown>
									))}
							</>
						) : (
							<p>Carregando...</p>
						)}
					</Nav>
				</Navbar.Collapse>
			</Container>
			<Dropdown className='dropdown'>
				<Dropdown.Toggle variant="link">
					<i className="bi-person-circle userIcon"></i>
				</Dropdown.Toggle>

				<Dropdown.Menu style={{ right: 0, left: 'auto' }}>
					<Dropdown.Item
						onClick={handleLogout}
					>
						Sair
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</Navbar>
	);
}

export default Menu