import axios from "axios";

export const axiosBase = axios.create({
  mode: "cors",
  baseURL: process.env.REACT_APP_API_URL
});

export const axiosInterceptor = (store, unauthorizedFunction) => {
  //Executa as funções antes de entregar a resposta
  // Mais sobre interceptors: https://axios-http.com/docs/interceptors
  axiosBase.interceptors.response.use(
    function (response) {
      // Faz algo caso a resposta esteja nos 2xx
      return response;
    },
    function (error) {
      // Faz algo caso seja um erro

      // 401 é unauthorized
      // caso seja manda pra página de login
      if (error?.response?.status === 401) {
        unauthorizedFunction();
        store.setUser(null);
      }
      return Promise.reject(error);
    }
  );
};
