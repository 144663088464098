import { Spinner } from 'react-bootstrap';
import './style.css'

const Loading = () => {

  return (
    <div className="loading-div">
      <Spinner
        animation="border"
        role="status"
        variant="primary"
        style={{ width: "50px", height: "50px" }}
      />
    </div>
  )
}

export default Loading
