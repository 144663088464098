import React, { Suspense, useEffect } from "react";
import Loading from "../components/Loading";
import history from "../common/utils/router";
import Menu from '../components/Menu'
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { authRoutes, generalRoutes } from "./routes";
import baseApiObject from "../api/baseApi";

const AppRoutes = () => {

  const initializeAuth = useStoreActions((actions) => actions.auth.initializeAuth);
  const isAuthenticated = useStoreState((state) => state.auth.isAuthenticated);

  useEffect(() => {
    initializeAuth(); // Inicializa a autenticação ao carregar o aplicativo
    baseApiObject.setAppAccessToken(localStorage.getItem('authToken'));
  }, [initializeAuth]);

  return (
    <BrowserRouter history={history}>
      <Suspense fallback={<Loading />}>
        {isAuthenticated && <Menu />}
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/home" /> : <Navigate to="/login" />} />

          {authRoutes.map(({ path, component, ...props }, index) => (
            <Route path={path} key={index} {...props} element={component} />
          ))}
          
          {generalRoutes.map(
            ({ path, component, ...props }, index) => (
              <Route
                path={path}
                key={index}
                {...props}
                element={component}
              />
            )
          )}

          {/* <Route path="/login" element={<Navigate to="/login" />}/> */}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
