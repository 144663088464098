import React from 'react'
import { StoreProvider } from "easy-peasy";
import Routes from "./routes/";
import storeStateManager from "./store/index";
import './App.css'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-pivottable/pivottable.css';

const App = () => {

  return (
		<div>
			<>
				<StoreProvider store={storeStateManager}>
					<Routes />
				</StoreProvider>
			</>
		</div>
  );
}

export default App
