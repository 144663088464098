import { lazy } from "react";
// import LoginContainer from "../screens/Auth/LoginScreen";

// AUTH SCREENS
const LoginContainer = lazy(() => import("../screens/Auth/LoginScreen"));
// const TokenValidationContainer = lazy(() => import("../screens/Auth/TokenValidation"));
// const ForgotPasswordContainer = lazy(() => import("../screens/Auth/ForgotPassword"));

// GENERAL SCREENS
const MainScreenContainer = lazy(() => import("../screens/MainScreen"));


export const authRoutes = [
  {
    id: "login",
    path: "login",
    component: <LoginContainer />,
  },
  // {
  //   id: "forgotPassword",
  //   path: "/forgotPassword",
  //   component: ForgotPasswordContainer,
  // },
  // {
  //   id: "tokenValidation",
  //   path: "/tokenValidation",
  //   component: TokenValidationContainer,
  // },
];

export const generalRoutes = [
  {
    id: "MainScreen",
    path: "/home",
    component: <MainScreenContainer />,
  },
  {
    id: "MainScreen",
    path: "/home/:screen",
    component: <MainScreenContainer />,
  },
];

export const allRoutes = [...authRoutes, ...generalRoutes];
